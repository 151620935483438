<template>
  <div class="box-dialog">
    <el-dialog
      width="600px"
      :title="selfData.id ? '编辑计量单位' : '新增计量单位'"
      :visible.sync="selfShow"
      :close-on-click-modal="false"
    >
      <el-form ref="refForm" :model="selfData" :rules="rules" label-width="100px">
        <el-form-item label="单位名称:" prop="unit_name">
          <el-input v-model="selfData.unit_name" size="mini" />
        </el-form-item>
        <el-form-item label="主计量单位:" prop="unit_val">
          <el-input v-model="selfData.unit_val" size="mini" />
        </el-form-item>
        <el-form-item label="计算公式:" class="p-y-5">
          <el-input
            v-model="formulaStr"
            type="textarea"
            size="mini"
            resize="none"
            :rows="4"
            disabled
          />
          <div class="btn-delete">
            <el-link
              class="btn-delete-one"
              type="primary"
              size="mini"
              title="删除最后格"
              @click="btnDelete"
            >
              删格
            </el-link>
            <el-link
              class="btn-delete-all"
              type="primary"
              size="mini"
              title="清空所有控件"
              @click="btnClear"
            >
              清空
            </el-link>
          </div>
        </el-form-item>
        <el-form-item label="密度控件:" class="contral-box">
          <el-button type="default" size="mini" @click="contralChoose('材质密度')">
            材质密度
          </el-button>
        </el-form-item>
        <el-form-item label="规格控件:" class="contral-box">
          <el-button
            v-for="(item, index) in effectGuigeList"
            :key="index"
            type="default"
            size="mini"
            @click="contralChoose(item)"
          >
            {{ item.name }}
          </el-button>
        </el-form-item>
        <el-form-item label="符号控件:" class="contral-box symbol">
          <el-button
            v-for="(item, index) in btnListFuhao"
            :key="index"
            type="default"
            size="mini"
            @click="contralChoose(item)"
          >
            {{ item }}
          </el-button>
        </el-form-item>
        <el-form-item label="数值控件:">
          <div class="flex flex-y-center">
            <el-input
              style="width: 136px"
              size="mini"
              type="number"
              clearable
              v-model.trim="shuzhiVal"
            />
            <el-button class="m-l-10" type="success" size="mini" @click="shuzhiConfirm">
              确认数值
            </el-button>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="popupCancel">取消</el-button>
        <el-button size="small" type="primary" @click="popupConfirm">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    guigeList: {
      type: Array,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selfShow: false,
      selfData: {
        column_data_id: '',
        unit_name: '',
        unit_val: '',
        gong_shi: [],
        huan_suan_lv_list: []
      },
      btnListFuhao: ['+', '-', '*', '/', '(', ')'],
      shuzhiVal: '',
      rules: {
        unit_name: [{ required: true, message: '请输入单位名称' }],
        unit_val: [{ required: true, message: '请输入主计量单位' }]
      }
    }
  },
  computed: {
    // 有效规格列表
    effectGuigeList() {
      return this.guigeList.filter((item) => {
        return item.id && item.name
      })
    },
    // 公式字符串
    formulaStr() {
      let result = this.selfData.gong_shi.map((item) => item.name)
      return result.join('')
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    },
    data: {
      handler(val) {
        if (JSON.stringify(this.selfData) !== JSON.stringify(val)) {
          let result = this.$util.deepCopy(val)
          result.huan_suan_lv_list.forEach((item) => {
            if (item.is_main) result.unit_val = item.unit_name
          })
          this.selfData = result
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.selfShow = this.show
  },
  methods: {
    // 栅格
    btnDelete() {
      this.selfData.gong_shi.splice(this.selfData.gong_shi.length - 1, 1)
    },
    // 清空
    btnClear() {
      this.selfData.gong_shi.splice(0, this.selfData.gong_shi.length)
    },
    // 数值确认
    shuzhiConfirm() {
      if (!this.shuzhiVal) return
      this.contralChoose(this.shuzhiVal)
      this.shuzhiVal = ''
    },
    // 控件选择
    contralChoose(val) {
      let result = {}
      if (typeof val === 'string') {
        result = {
          name: val,
          id: ''
        }
      } else {
        result = {
          name: val.name,
          id: val.id
        }
      }
      this.selfData.gong_shi.push(result)
    },
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    },
    // 弹窗确认
    popupConfirm() {
      this.$refs.refForm.validate((valid) => {
        if (valid) {
          if (!this.selfData.gong_shi.length) this.selfData.gong_shi.push(1)
          if (this.selfData.huan_suan_lv_list.length) {
            this.selfData.huan_suan_lv_list.forEach((item) => {
              if (item.is_main) item.unit_name = this.selfData.unit_val
            })
          } else {
            let obj = {
              unit_name: this.selfData.unit_val,
              huan_suan_lv: '1',
              is_main: true
            }
            this.selfData.huan_suan_lv_list.push(obj)
          }
          this.$emit('success', this.selfData)
          this.popupCancel()
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.box-dialog {
  .btn-delete {
    position: absolute;
    right: 0;
    bottom: 0;
    line-height: 28px;
    .btn-delete-one,
    .btn-delete-all {
      line-height: 1.5;
      margin-right: 10px;
    }
  }
  .contral-box {
    /deep/ .el-button {
      min-width: 28px;
    }
  }
}
</style>
